import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ReviewModule from '../components/ReviewModule';

const uuidv4 = require('uuid/v4');
const _ = require('lodash');

const ReviewList = ({ data, pageContext }) => {
  const { numPages, type, currentPage } = pageContext;
  const posts = data.allMarkdownRemark.edges;
  const prev = currentPage === 2 ? `/${type}s/` : `/${type}s/${currentPage - 1}`;
  const next = `/${type}s/${currentPage + 1}`;
  return (
    <Layout>
      <SEO title={`${_.capitalize(type)}s`} />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 reviews-full">
            <h3>{`${_.capitalize(type)}s`}</h3>
            <ul className="container">
              {posts.map(({ node }) => {
                const { slug } = node.fields;
                const {
                  title, teaserimg, tags, snippet, date,
                } = node.frontmatter;
                const reviewData = {
                  slug,
                  type: node.fields.type,
                  teaserimg: teaserimg.childImageSharp.fluid,
                  title,
                  tags,
                  snippet,
                  date,
                };

                return (
                  <ReviewModule key={uuidv4()} data={reviewData} />
                );
              })}
            </ul>
            <div className="container mb-5">
              <div className="row">
                <div className="col-6 text-center">
                  <div className={currentPage === 1 ? ' d-none' : ''}>
                    <Link to={prev}>Newer</Link>
                  </div>
                </div>
                <div className="col-6 text-center">
                  <div className={currentPage === numPages ? ' d-none' : ''}>
                    <Link to={next}>Older</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

ReviewList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
              type: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export const reviewListQuery = graphql`
  query reviewListQuery($skip: Int!, $limit: Int! $regex: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fields: {type: {regex: $regex}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            type
          }
          frontmatter {
            title
            tags
            date
            snippet
            teaserimg {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ReviewList;
